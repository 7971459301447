import React, { useEffect } from "react";
import "./Scores.css";
import fish from "../../images/Just the fish.png";

const Scores = ({ show, scores, players }) => {
  useEffect(() => {
    const ele = document.querySelector(".active");
    if (ele) {
      ele.scrollIntoView();
    }
  }, []);

  let total = [0, 0, 0, 0];
  let rowScore = 0;

  return (
    <div className="scores">
      <table>
        <thead>
          <tr className={`titles ${show}`}>
            <th>Round</th>
            {Object.values(players).map((player, index) => (
              <th key={index}>{player.name}</th>
            ))}
            {show && (
              <th
                style={{
                  fontWeight: "normal",
                  color: "#ffde59",
                  maxWidth: "90px",
                }}
              >
                Community Score
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {scores &&
            Object.values(scores).map((score, index) => {
              let row = 0;
              return (
                <tr
                  key={index}
                  className={
                    index === Object.keys(scores).length - 1 ? "active" : ""
                  }
                >
                  <td>
                    {index === 4
                      ? `#${index + 1} X3`
                      : index === 7
                      ? `#${index + 1} X5`
                      : index === 9
                      ? `#${index + 1} X10`
                      : `#${index + 1}`}
                  </td>
                  {score &&
                    Object.values(score).map((indivScore, ind) => {
                      total[ind] += indivScore.indivScore;
                      row += indivScore.indivScore;
                      rowScore += indivScore.indivScore;
                      var noOfFishes = "1";
                      if (indivScore.isSubmit.choice) {
                        noOfFishes = indivScore.isSubmit.choice;
                      } else {
                        noOfFishes = indivScore.isSelected.choice;
                      }
                      return (
                        <td key={ind}>
                          {noOfFishes == "1" ? (
                            <div className="flex justify-center">
                              <img style={{ maxWidth: "25px" }} src={fish} />
                            </div>
                          ) : noOfFishes == "2" ? (
                            <div className="flex justify-center">
                              <img style={{ maxWidth: "25px" }} src={fish} />
                              &nbsp;
                              <img style={{ maxWidth: "25px" }} src={fish} />
                            </div>
                          ) : (
                            <div className="flex justify-center">
                              -
                            </div>
                          )}
                          <span
                            style={{
                              padding: "2px",
                              backgroundColor:
                                indivScore.indivScore >= 0 ? "green" : "red",
                            }}
                          >
                            &#8377; {indivScore.indivScore}
                          </span>
                        </td>
                      );
                    })}
                  {show && <td className="display">{row}</td>}
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr className="total">
            <td>Total</td>
            {total.map((score, ind) => (
              <td key={ind}>{score}</td>
            ))}
            {show && <td className="row-total display">{rowScore}</td>}
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Scores;
