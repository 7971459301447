import React, { useContext, useEffect, useState } from "react";
import FlashCard from "../../components/Flashcard/Flashcard";
import Button from "../../components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext, CodeContext } from "../../context/context";
import "./Lobby.css";
import InfoIcon from "@mui/icons-material/Info";
import logo from "../../images/logo_1.png";
import { auth, database, database as db } from "../../firebase";
import {
  ref,
  child,
  get,
  push,
  update,
  onValue,
  updateStarCount,
  onSnapshot,
  doc,
  set,
} from "firebase/database";
import useFirebaseRef from "../../utils/useFirebaseRef";
import toast from "react-hot-toast";
import "../../components/Global.css";
import fishyLogo from "../../images/logo.png";
import Rules from "../Rules/Rules";

const Lobby = () => {
  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState(false);
  const { id } = useParams();
  const [timer, setTimer] = useState(120);
  const code = id;
  const user = useContext(UserContext);
  const [rules, showRules] = useState(false);
  const [properties, loading] = useFirebaseRef(
    "sessions/" + code + "/properties"
  );
  const [timerref] = useFirebaseRef("sessionData/" + code + "/state/" + id);
  const [players] = useFirebaseRef("sessions/" + code + "/users");

  const ruleHandler = () => {
    showRules(!rules);
  };
  const [hostProperties, loading2] = useFirebaseRef(
    `sessionData/${id}/hostProperties`
  );
  const waitingRoom = () => {
    if (hostProperties && hostProperties.movetoWaitingRoom) {
      //console.log('move to waitin');
      navigate(`/game/${id}/waiting`);
    }
  };

  const clickHandler = () => {
    if (properties && Object.keys(players).length === 4) {
      //console.log(snapshot.val());
      const data = properties;
      const updates = {};
      updates["sessions/" + code + "/properties"] = {
        ...data,
        isStarted: false,
      };
      // update(ref(db,"/sessionData/" + code + "/timerState/1"),{timer:timer});
      update(ref(db), updates);
      get(child(ref(db), `sessionData/${id}/hostProperties`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const res = snapshot.val();
            const updates = {};
            updates[`sessionData/${id}/hostProperties`] = {
              ...res,
              movetoWaitingRoom: true,
            };
            update(ref(db), updates);
            console.log(res);
            // if (res.isOver) {
            navigate(`/game/${id}/waiting`);
            // }
          } else {
            console.log("No data available");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.log("No data available");
    }
  };

  const isAuthUser = () => {
    console.log(user);
    if (properties && user) {
      if (properties.host.userID === user.id) {
        setAuthUser(true);
      }
    }
  };

  const watchIfStarted = () => {
    if (properties && properties.isStarted === true) {
      //console.log('yes');
      //console.log(players);
      Object.entries(players).forEach((element) => {
        console.log(element);
      });
      navigate(`/game/${code}/round/${1}`, { replace: true });
    }
  };

  useEffect(() => {
    watchIfStarted();
    isAuthUser();
  }, [properties, user]);

  useEffect(() => {
    waitingRoom();
  }, [loading2, hostProperties]);

  return (
    <div>
      {auth.currentUser && !auth.currentUser.isAnonymous && (
        <div className="right-2 top-1 absolute z-1000">
          <button
            className={`btn-lg min-w-[90px] text-white inline-block bg-center bg-gradient-to-r from-[#ffde59] to-[#ff914d] mt-1`}
            onClick={() => {
              auth.signOut();
              navigate("/host");
            }}
          >
            {"Logout"}
          </button>
        </div>
      )}
      <div
        onClick={ruleHandler}
        className="absolute ml-5 mt-3 mr-auto text-white cursor-pointer md:text-[18px] xs:text-[30px] flex align-middle"
      >
        <InfoIcon /> &nbsp;
        <span className="xs:hidden md:block">Game Instructions</span>
      </div>
      <div className="p-3">
        <img
          src={logo}
          alt="Logo"
          className="mx-auto xs:w-[60vw] sm:w-[40vw] md:w-[18vw] lg:w-[18vw]"
        />
        <div className="flex justify-center align-middle xs:mt-[10vw] md:mt-[3vw]">
          <span className="xs:text-[18px] md:text-[25px] lg:text-[25px] text-white font-bold">{`Room Code: ${code}`}</span>
        </div>
      </div>
      <div className="flex justify-center mt-4 mb-3">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5">
          {players &&
            Object.values(players).map((player, index) => (
              <div
                key={index}
                className="bg-gradient-to-r from-[#ffde59] to-[#ff914d] p-2 min-w-[150px] text-white font-bold text-center rounded-lg"
              >
                {player.name}
              </div>
            ))}
        </div>
      </div>

      <center>
        {authUser && !loading ? (
          players && Object.keys(players).length === 4 ? (
            <Button text={"START"} clickHandler={clickHandler} />
          ) : (
            //   <Button
            //   display={"bg-btn-bg-primary bg-center btn-lg"}
            //   text={"Start Game"}
            //   clickHandler={clickHandler}
            // />
            // <button
            //   className="text-warning bg-btn-bg-primary btn-lg bg-center self-center disabled:opacity-50 cursor-default"
            //   disabled
            //   style={{ marginTop: "1rem" }}
            // >
            //   Start Game{" "}
            // </button>
            <button
              className="text-warning bg-btn-bg-primary btn-lg bg-center self-center disabled:opacity-50 cursor-default mt-5"
              disabled
              style={{ marginTop: "1rem" }}
            >
              Start
            </button>
          )
        ) : null}
      </center>
      {rules ? <Rules showRules={() => showRules(false)} /> : null}
    </div>
  );
};

export default Lobby;
