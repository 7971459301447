import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./HostScreen.css";
import { auth, database as db } from "../../../firebase";
import { toast } from "react-hot-toast";
import { child, get, ref } from "firebase/database";

const HostScreen = () => {
  const [resumeStatus, setResumeStatus] = useState(false);
  const [code, setCode] = useState("");
  const navigate = useNavigate();
  const joinGame = async () => {
    if (code == "") {
      toast.error("Please Enter Room Code!");
    } else {
      const hostProperties = await get(
        child(ref(db), `sessionData/${code}/hostProperties`)
      );
      const properties = await get(
        child(ref(db), `sessions/${code}/properties`)
      );
      if (properties.exists()) {
        const propertiesVal = properties.val();
        if (propertiesVal.isOver) {
          navigate("/gameover");
        }
      }
      if (hostProperties.exists()) {
        const hostPropertiesVal = hostProperties.val();
        if (
          hostPropertiesVal.movetoWaitingRoom ||
          (hostPropertiesVal.nextRound && !hostPropertiesVal.roundNo)
        ) {
          navigate(`/game/${code}/waiting`);
        } else if (hostPropertiesVal.isOver && hostPropertiesVal.roundNo) {
          navigate(`/game/${code}/host/results/${hostPropertiesVal.roundNo}`);
        } else if (hostPropertiesVal.roundNo) {
          navigate(`/game/${code}/round/${hostPropertiesVal.roundNo}`);
        } else {
          navigate(`/lobby/${code}/waiting`);
        }
      } else {
        navigate(`/lobby/${code}/waiting`);
      }
    }
  };
  return (
    <div>
      <div className="w-full flex flex-col bg-card bg-no-repeat bg-cover bg-blend-screen shadow-lg rounded-lg px-8 pt-6 pb-8 mt-10  h-full text-white justify-center items-center host-btn">
        <p className="pt-2 text-2xl font-bold">Welcome to Host Fishy Fest!</p>
        {resumeStatus && (
          <div className="mb-4">
            <label
              htmlFor="Code"
              className="block text-white text-base font-bold mb-2 py-1 "
            >
              Room Code :
            </label>
            <input
              type="text"
              placeholder="Eg:12345"
              value={code}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              onChange={(e) => setCode(e.target.value)}
              required
            ></input>
          </div>
        )}
        <div className="flex justify-center space-x-4">
          <Link to="/admin/link">
            <button
              className={`btn-lg min-w-[90px] text-white font-400 inline-block bg-center bg-gradient-to-r from-[#ffde59] to-[#ff914d] mt-3`}
            >
              {"New Game"}
            </button>
          </Link>
          {resumeStatus && (
            <button
              onClick={() => {
                joinGame();
              }}
              className={`btn-lg min-w-[90px] text-white font-400 inline-block bg-center bg-gradient-to-r from-[#ffde59] to-[#ff914d] mt-3`}
            >
              {"Join"}
            </button>
          )}
          {!resumeStatus && (
            <button
              onClick={() => {
                setResumeStatus(true);
              }}
              className={`btn-lg min-w-[90px] text-white font-400 inline-block bg-center bg-gradient-to-r from-[#ffde59] to-[#ff914d] mt-3`}
            >
              {"Resume"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default HostScreen;
