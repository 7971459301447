import React, { useContext, useState, useEffect } from "react";
import "./Timer.css";
import { useTimer } from "react-timer-hook";
import { AuthContext, CodeContext, UserContext } from "../../context/context";
import Icons from "../Icons/Icons";
import Pause from "../../images/pause.png";
import Resume from "../../images/resume.png";
import { database as db } from "../../firebase";
import { get, child, ref, onValue, update } from "firebase/database";
import useFirebaseRef from "../../utils/useFirebaseRef";
import { useNavigate } from "react-router-dom";

function MyTimer({
  expiryTimestamp,
  startTime,
  authUser,
  code,
  round,
  userID,
  setTimer,
  sum
}) {
  console.log(typeof(endTime))
  const navigate = useNavigate();
  const path = authUser
    ? `sessionData/${code}/hostProperties`
    : `sessionData/${code}/state/${round}/${userID}`;
  //  console.log(path);
  const [roundData, loading] = useFirebaseRef(path);
  const [stopStatus, loading2] = useFirebaseRef(
    `sessionData/${code}/hostProperties`
  );
  const expiryHandler = () => {
    setTimer(true);
  };
  const { seconds, minutes, isRunning, start, pause, resume, restart } =
    useTimer({
      expiryTimestamp,
      onExpire: () => {
        //console.log("onExpire called");
        expiryHandler();
      },
    });

  const setStopStatus = (bool) => {
    if (!loading) {
      const updates = {};
      updates[`sessionData/${code}/hostProperties`] = {
        ...stopStatus,
        stopTimer: bool,
      };
      update(ref(db), updates);
    }
  };

  const getTimerPercentage = () => {
    const currentTime = new Date();
    const totalDuration = expiryTimestamp - startTime;
    const elapsedTime = currentTime - startTime;
    const percentage = (elapsedTime / totalDuration) * 100;
    return parseFloat(Math.min(100, Math.max(0, percentage)).toFixed(2)); // Clamp the percentage between 0 and 100
  };

  const timerPercentage = getTimerPercentage();

  return (
    <div style={{ textAlign: "center" }}>
      <div class="w-full h-4 mb-4 mt-12 bg-white rounded-full min-w-[35vw] border-[#ff914d]">
        <div
          class="h-4 bg-[#ff914d] rounded-full"
          style={{width: `${timerPercentage || 0}%`}}
        ></div>
      </div>
      <div style={{ fontSize: "38px", color: "white" }}>
        <span>{minutes}</span>:<span>{seconds}</span>
        <span></span>
      </div>
    </div>
  );
}

const Timer = ({ startTime, endTime, round, code, userID, setTimer }) => {
  const [toggle, settoggle] = useState(true);
  const { user } = useContext(UserContext);
  // const userID = user.id;
  // 10 minutes timer

  // console.log(auth);
  const [properties, loading1] = useFirebaseRef(
    "sessions/" + code + "/properties"
  );
  const [authUser, setAuthUser] = useState(false);

  useEffect(() => {
    if (properties && user) {
      if (properties.host.userID === user.id) {
        setAuthUser(true);
      } else {
        setAuthUser(false);
      }
    }
  }, [properties, user, loading1]);

  return (
    <div>
      <MyTimer
        setTimer={setTimer}
        expiryTimestamp={endTime}
        startTime={startTime}
        code={code}
        toggle={{ toggle, settoggle }}
        round={round}
        authUser={authUser}
        userID={userID}
      />
    </div>
  );
};

export default Timer;
