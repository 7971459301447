import { initializeApp } from 'firebase/app';
import {
	getAuth,
	signInAnonymously,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	connectAuthEmulator,
} from 'firebase/auth';
import { getDatabase, connectDatabaseEmulator } from 'firebase/database';
import { connectFunctionsEmulator } from 'firebase/functions';
import { getAnalytics } from 'firebase/analytics';

import config, { isDev } from './config';

const app = initializeApp({

	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DB_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MSG_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID
});
export const auth = getAuth(app);
//console.log(app);

// Get a reference to the database service
export const database = getDatabase(app);

/*if (isDev) {
	connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
	connectDatabaseEmulator(database, 'localhost', 9000);
	// connectFunctionsEmulator(function,"localhost",5001);
} else {
	getAnalytics(app);
}
*/
export { signInAnonymously, onAuthStateChanged, signInWithEmailAndPassword };
