import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, auth } from "../../../firebase";

import "./LoginScreen.css";
import logo from "../../../images/logo_1.png";
import { toast } from "react-hot-toast";
import useFirebaseRef from "../../../utils/useFirebaseRef";
import Button from "../../../components/Button/Button";

const LoginScreen = () => {
  const Navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginDisabled, setIsLoginDisabled] = useState(false);

  const handleLogin = async () => {
    try {
      setIsLoginDisabled(true);
      var log = await signInWithEmailAndPassword(auth, email, password)
        .then((e) => {
          setIsLoginDisabled(false);
          Navigate("/host");
        })
        .catch((e) => {
          toast.error("Login Failed!");
          console.log("error");
          setIsLoginDisabled(false);
        });
      // addCount(log);
      // history.push("/admin");
    } catch (e) {
      console.log(e);
      toast.error(e.message);
      setIsLoginDisabled(false);
    }
  };

  return (
    <div className="flex items-center justify-center p-3">
      {auth.app && (
        <div>
          <img
            src={logo}
            alt="Logo"
            className="mx-auto xs:w-[60vw] sm:w-[40vw] md:w-[18vw] lg:w-[18vw]"
          />
          <div className="w-full flex flex-col bg-card bg-no-repeat bg-cover bg-blend-screen shadow-lg rounded-lg px-8 pt-6 pb-8 mt-10  h-full text-white justify-center items-center host-btn">
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-white text-base font-bold mb-2 py-1 "
              >
                Email:
              </label>
              <input
                type="text"
                placeholder="Enter Email"
                value={email}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={(e) => setEmail(e.target.value)}
                required
              ></input>
            </div>

            <div className="mb-6">
              <label
                className="block text-white text-base font-bold mb-2 py-1"
                htmlFor="password"
              >
                Password:
              </label>
              <input
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                required
              ></input>
            </div>
            <div className="self-center join-btn">
              <Button
                disabled={isLoginDisabled}
                text="Login"
                clickHandler={handleLogin}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginScreen;
