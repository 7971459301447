import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import FlashCard from "../../../components/Flashcard/Flashcard";
import { CodeContext } from "../../../context/context";
import ShowOptions from "../../Host/ShowOptions/ShowOptions";

import Fish1 from "../../../images/Fish1-new.png";
import Fish2 from "../../../images/Fish2-new.png";

import useFirebaseRef from "../../../utils/useFirebaseRef";
import "../../../components/Global.css";
import mainLogo from "../../../images/logo_1.png";
import moneyBag from "../../../images/money-bag.png";
import multiplier3x from "../../../images/3x_multiplier.png";
import multiplier5x from "../../../images/5x_multiplier.png";
import multiplier10x from "../../../images/10x_multiplier.png";
import { Dialog, DialogContent } from "@mui/material";
import { calculateScore } from "../../../utils/scoreHelper";
import Scores from "../../../components/Scores/Scores";
import InfoIcon from "@mui/icons-material/Info";
import dayIcon from "../../../images/day-icon.png";
import Rules from "../../Rules/Rules";
import { makeStyles } from "@mui/styles";

const SeeResults = () => {
  let { roomId, id } = useParams();
  let multiplier = useRef(0);
  // const { code } = useContext(CodeContext);
  const navigate = useNavigate();

  const classes = makeStyles((theme) => ({
    transparentDialog: {
      backgroundColor: "transparent",
      width: "100%",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));
  const [score, showScore] = useState(false);
  const [rules, showRules] = useState(false);
  const [players, loading] = useFirebaseRef(
    `sessionData/${roomId}/state/${id}`
  );
  const [hostProperties, loading1] = useFirebaseRef(
    `sessionData/${roomId}/hostProperties`
  );

  const [scoreData, scoreLoading] = useFirebaseRef(
    `sessionData/${roomId}/state`,
    true
  );

  const [playerData, playerLoading] = useFirebaseRef(
    `sessions/${roomId}/users`
  );

  const ruleHandler = () => {
    showRules(!rules);
  };

  if (Number(id) === 5) {
    multiplier.current = (
      <img
        src={multiplier3x}
        className="md:max-w-[70px] xs:max-w-[40px]"
        alt="3x"
      />
    );
  } else if (Number(id) === 8) {
    multiplier.current = (
      <img
        src={multiplier5x}
        className="md:max-w-[70px] xs:max-w-[40px]"
        alt="5x"
      />
    );
  } else if (Number(id) === 10) {
    multiplier.current = (
      <img
        src={multiplier10x}
        className="md:max-w-[65px] xs:max-w-[30px]"
        alt="10x"
      />
    );
  }
  useEffect(() => {
    if (!loading1 && hostProperties) {
      if (hostProperties.showScore) navigate(`/game/${roomId}/player/scores`);
    }
  }, [id, loading1, hostProperties]);
  return (
    <div>
      <div className="float-left ml-3 mt-2">
        <div className="flex flex-col items-start">
          <img
            src={mainLogo}
            alt="Logo"
            className="xs:max-w-[24vw] sm:max-w-[24vw] md:max-w-[10vw] lg:max-w-[10vw] max-h-[20vh]"
          />
          <div
            onClick={ruleHandler}
            className="text-white mt-2 z-20 cursor-pointer md:text-[18px] xs:text-[30px] flex align-middle items-center"
          >
            <InfoIcon /> &nbsp;
            <span className="xs:text-[14px] md:text-[18px] xs:max-w-[12%] md:block">
              Game Instructions
            </span>
          </div>
        </div>
      </div>
      <div className="float-right mr-3 mt-2">
        <div className="flex flex-col items-end text-white xs:text-[14px] md:text-[18px]">
          <img
            className="xs:max-w-[12vw] sm:max-w-[12vw] md:max-w-[5vw] lg:max-w-[5vw] cursor-pointer z-20"
            src={moneyBag}
            onClick={() => {
              showScore(true);
            }}
          />
          <b>Score Card</b>
        </div>
      </div>
      <div className="p-1 w-full absolute mt-4 flex flex-col h-screen game">
        <div>
          <div className="relative flex justify-center items-center">
            <div className="absolute mt-3 flex justify-center items-center">
              <span className="absolute pt-3 text-[#fdc440] font-semibold">
                {id}
              </span>
              <img src={dayIcon} className="xs:max-h-[50px] md:max-h-[80px]" />
            </div>
            {Number(id) === 5 || Number(id) === 8 || Number(id) === 10 ? (
              <p className="relative rounded-full text-center mt-3 px-2 py-2 md:ml-[20vw] xs:ml-[30vw]">
                {multiplier.current}
              </p>
            ) : null}
          </div>
        </div>
        {score ? (
          <Dialog
            open={score}
            fullWidth
            PaperProps={{
              style: {
                backgroundImage: "url(../../../images/bg_new.png)",
                background:
                  "linear-gradient(to bottom right, #5de0e6, #0467af)",
                minWidth: "80%",
              },
            }}
            onClose={() => {
              showScore(false);
            }}
          >
            <div className="flex flex-col justify-center items-center h-screen">
              <div className="xs:text-[24px] md:text-[30px] text-center text-white">
                <b>Scores</b>
              </div>
              <div className="flex justify-center self-center w-max overflow-visible xs:w-full md:min-w-[700px]">
                {scoreData && playerData && !scoreLoading && !playerLoading ? (
                  <Scores
                    show={score}
                    scores={calculateScore(scoreData)}
                    players={playerData}
                  />
                ) : null}
              </div>
            </div>
          </Dialog>
        ) : null}
        {rules ? <Rules showRules={() => showRules(false)} /> : null}
        <div className="flex my-auto xs:flex-wrap justify-center items-center">
          {players &&
            !loading &&
            Object.values(players).map((player, index) => {
              return (
                <div
                  className="p-2 grid grid-gap-2 align-middle items-center text-center"
                  key={Math.random()}
                >
                  {/* <FlashCard text={p.name} /> */}
                  <div className="team-title min-w-[80%]">{player.name}</div>
                  {/*console.log('player choice ' + player.isSubmit.choice)*/}
                  {player.eye ? (
                    <div className="mt-3 xs-mobile:ml-auto xs-mobile:mr-auto">
                      {Number(player.isSubmit.choice) === 2 ? (
                        <ShowOptions fishes={Fish2} />
                      ) : (
                        <ShowOptions fishes={Fish1} />
                      )}
                    </div>
                  ) : (
                    <div className="mt-3 xs-mobile:ml-auto xs-mobile:mr-auto">
                      <ShowOptions />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SeeResults;
