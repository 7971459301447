import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import FlashCard from "../../../components/Flashcard/Flashcard";
import Icons from "../../../components/Icons/Icons";
import Scores from "../../../components/Scores/Scores";
import Button from "../../../components/Button/Button";

import "./Scoreboard.css";
import eye from "../../../images/eye-new.png";
import moneyBag from "../../../images/money-bag.png";
import eyeOff from "../../../images/eye-new-off.png";
import titleCard from "../../../images/title-card.png";
import mainLogo from "../../../images/logo_1.png";
import Rules from "../../Rules/Rules";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";

import { ref, update, get, child, onValue, set } from "firebase/database";
import { auth, database as db } from "../../../firebase";
import useFirebaseRef from "../../../utils/useFirebaseRef";
import { calculateScore } from "../../../utils/scoreHelper";
import Heading from "../../../components/Heading";
import { Dialog } from "@mui/material";

const Scoreboard = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const [quit, showQuit] = useState(false);
  const [rules, showRules] = useState(false);
  const [show, setShow] = useState(false);
  const [roundNo, setRoundNo] = useState(0);
  const [score, setScore] = useState(null);
  const [playerData, loading] = useFirebaseRef(`sessions/${roomId}/users`);
  const [scoreData, loading1] = useFirebaseRef(
    `sessionData/${roomId}/state`,
    true
  );

  const [properties, loading3] = useFirebaseRef(
    "sessions/" + roomId + "/properties"
  );

  const clickHandler = () => {
    setShow(!show);
  };

  const reallyQuitGame = () => {
    const updates = {};
    updates[`sessions/${roomId}/properties`] = {
      ...properties,
      isOver: true,
    };
    update(ref(db), updates);
    navigate("/gameover");
  };

  const ruleHandler = () => {
    showRules(!rules);
  };

  useEffect(() => {
    if (!loading1 && scoreData) setScore(calculateScore(scoreData));
  }, [scoreData, loading1]);

  useEffect(() => {
    if (properties && properties.isOver) {
      navigate("/gameover");
    }
  }, [properties, loading3]);

  useEffect(() => {
    if (!loading1 && scoreData) updateScore();
  }, [score]);

  const quitGame = () => {
    showQuit(true);
  };

  const updateScore = () => {
    const currScore = Object.entries(score).at(-1);
    setRoundNo(currScore[0]);
    get(ref(db, `sessionData/${roomId}/state/${currScore[0]}`)).then(
      (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const updates = {};
          for (let id in data) {
            updates[`sessionData/${roomId}/state/${currScore[0]}/${id}`] = {
              ...data[id],
              indivScore: currScore[1][id].indivScore,
            };
            update(ref(db), updates);
          }
        } else {
          console.log("Round doesn't exist");
        }
      }
    );
  };

  const clickHandler2 = () => {
    get(child(ref(db), `sessionData/${roomId}/hostProperties`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const res = snapshot.val();
          const updates = {};
          updates[`sessionData/${roomId}/hostProperties`] = {
            ...res,
            movetoWaitingRoom: true,
          };
          update(ref(db), updates);
          if (res.isOver) {
            navigate(`/game/${roomId}/waiting`);
          }
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      <div className="absolute left-3 top-2">
        <div className="flex flex-col items-start">
          <img
            src={mainLogo}
            alt="Logo"
            className="xs:max-w-[24vw] sm:max-w-[24vw] md:max-w-[10vw] lg:max-w-[10vw] max-h-[20vh]"
          />
          <div
            onClick={ruleHandler}
            className="text-white mt-2 z-20 cursor-pointer md:text-[18px] xs:text-[30px] flex align-middle items-center"
          >
            <InfoIcon /> &nbsp;
            <span className="xs:hidden md:block max-w-[25px]">
              Game Instructions
            </span>
          </div>
        </div>
      </div>
      <div className="absolute right-3 top-2">
        {auth && auth.currentUser && !auth.currentUser.isAnonymous && (
          <div className="flex flex-col items-end text-white xs:text-[14px] md:text-[18px]">
            <div
              className="flex items-center cursor-pointer"
              onClick={quitGame}
            >
              End Game &nbsp;
              <CancelIcon />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center items-center h-full">
        <div className="flex justify-center items-center xs:flex-col md:flex-row">
          <img src={moneyBag} className="max-w-[65px]" /> &nbsp;
          <span className="text-[32px] text-[#996633]">Score Card</span>
        </div>
        <br />
        <div
          style={{
            background: "linear-gradient(to right,#94def3, #0a7cb0)",
            borderRadius: "10px",
          }}
          className="flex drop-shadow-sm justify-center self-center xs-mobile:w-full md:w-4/6 ml-auto mr-auto relative"
        >
          <div className="cursor-pointer absolute right-0">
            <img
              src={show ? eye : eyeOff}
              className="max-w-[40px] w-full"
              onClick={clickHandler}
            />
          </div>
          {playerData && !loading && scoreData && !loading1 ? (
            <Scores show={show} scores={score} players={playerData} />
          ) : null}
        </div>

        {!loading ? (
          <Link
            to={{
              pathname: `/game/${roomId}/waiting`,
            }}
          >
            {roundNo < 10 && (
              <Button
                text={"Next Round"}
                display={"bg-btn-bg-primary bg-center p-3 mt-2 btn-lg"}
                clickHandler={() => clickHandler2()}
              />
            )}
            {roundNo >= 10 && (
              <Button
                text={"Quit Game"}
                display={"bg-btn-bg-primary bg-center p-3 mt-2 btn-lg"}
                clickHandler={() => reallyQuitGame()}
              />
            )}
          </Link>
        ) : null}
        {quit ? (
          <Dialog
            open={quit}
            onClose={() => {
              showQuit(false);
            }}
          >
            <div className="inline-flex justify-end w-full">
              <div className="inline-block">
                <button
                  onClick={() => showQuit(false)}
                  className="text-light text-2xl hover:text-light"
                >
                  &times;
                </button>
              </div>
            </div>
            <div className="self-center flex justify-center items-center flex-col ml-auto mr-auto">
              <div>
                <Heading
                  text={`Are you sure you want to End the game?`}
                  display={`text-4xl font-bold text-white`}
                />
              </div>
              <div>
                <Button
                  clickHandler={reallyQuitGame}
                  display={
                    "btn bg-btn-bg-primary btn-sm text-warning border-2 border-yellow mb-5"
                  }
                  text={"End"}
                />
              </div>
            </div>
          </Dialog>
        ) : null}
        {rules ? <Rules showRules={() => showRules(false)} /> : null}
        <br />
      </div>
    </div>
  );
};

export default Scoreboard;
