import React from "react";
import "./Button.css";

const Button = ({ clickHandler, text, display }) => {
  if (clickHandler) {
    return (
      <button
        onClick={(e) => clickHandler(e)}
        className={`${display} btn-lg min-w-[90px] text-[20px] font-medium py-2.5 rounded-[15px] px-5 text-white inline-block bg-center bg-gradient-to-r from-[#ff914d] to-[#e8a04d] mt-5`}
      >
        {text}
      </button>
    );
  }
  return (
    <button className={`${display} btn-lg min-w-[90px] text-white font-400 inline-block bg-center bg-gradient-to-r from-[#ffde59] to-[#ff914d] mt-5`}>
      {text}
    </button>
  );
};

export default Button;
