import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Timer from "../../../components/Timer/Timer";
import ChoiceAndSubmit from "../ChoiceAndSubmit/ChoiceAndSubmit";
import { ref, update, get, child } from "firebase/database";
import { database as db } from "../../../firebase";
import "./Discussion.css";
import Heading from "../../../components/Heading";
import useFirebaseRef from "../../../utils/useFirebaseRef";
import "../../../components/Global.css";
import logo from "../../../images/day-icon.png";
import mainLogo from "../../../images/logo_1.png";
import moneyBag from "../../../images/money-bag.png";
import Rules from "../../Rules/Rules";
import InfoIcon from "@mui/icons-material/Info";
import { Dialog, DialogContent, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import Scores from "../../../components/Scores/Scores";
import { calculateScore } from "../../../utils/scoreHelper";
import dayIcon from "../../../images/day-icon.png";
import multiplier3x from "../../../images/3x_multiplier.png";
import multiplier5x from "../../../images/5x_multiplier.png";
import multiplier10x from "../../../images/10x_multiplier.png";

const Discussion = () => {
  const { roomId, id } = useParams();
  const [quit, showQuit] = useState(false);
  const [timerExpire, setExpire] = useState(false);
  //console.log(id);
  const [sum, setSum] = useState(0);
  const navigate = useNavigate();
  const [roundData, loading4] = useFirebaseRef(
    `sessionData/${roomId}/state/${id}`
  );
  const [timer, setTimer] = useState(null);
  let multiplier = useRef(0);
  const [timerStatus, setTimerStatus] = useState(true);
  const [time, loading2] = useFirebaseRef(`sessions/${roomId}/properties`);
  const [timePercent, setTimePercent] = useState(0);
  const [mode, setMode] = useState(false);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [hostPropertiesStatus, setHostPropertiesStatus] = useState(true);
  const [rules, showRules] = useState(false);
  const [score, showScore] = useState(false);
  // const { code } = useContext(CodeContext);
  const [playerInfo, loading] = useFirebaseRef(
    `sessionData/${roomId}/state/${id}`
  );

  const [scoreData, scoreLoading] = useFirebaseRef(
    `sessionData/${roomId}/state`,
    true
  );

  const [playerData, playerLoading] = useFirebaseRef(
    `sessions/${roomId}/users`
  );
  //console.log(playerInfo);
  const [hostProperties, loading1] = useFirebaseRef(
    `sessionData/${roomId}/hostProperties/`
  );
  
  useEffect(() => {
    if(hostProperties && !timerStatus && timer){
      setStartTime(hostProperties['startTime']?new Date(hostProperties['startTime']):new Date())
      var tempEndTime = hostProperties['startTime']?new Date(hostProperties['startTime']):new Date()
      setEndTime(timer)
      console.log(tempEndTime.getSeconds()+parseInt(timer))
      tempEndTime.setSeconds(tempEndTime.getSeconds()+parseInt(timer))
      setEndTime(tempEndTime)
      console.log(hostProperties['startTime']?new Date(hostProperties['startTime']):new Date())
      console.log(tempEndTime)
      setHostPropertiesStatus(false)
    }
  }, [hostProperties, loading1, timerStatus, timer]);

  //console.log(hostProperties);
  // console.log(code);

  const getTimer = () => {
    //console.log(time);
    get(child(ref(db), `sessionData/${roomId}/timerState/${id}`))
      .then((snapshot) => {
        if (snapshot.val().timer) {
          const res = snapshot.val().timer;
          console.log(res);
          setTimer(res);
        } else {
          console.log("No data available");
        }
        setTimerStatus(false);
      })
      .catch((error) => {
        setTimerStatus(false);
        console.error(error);
      });
  };
  const checkIfOver = (manualClose) => {
    if (playerInfo) {
      const data = Object.values(playerInfo);
      var tempSum = 0;
      for (let i = 0; i < data.length; i++) {
        if (data[i].isSubmit.status) {
          tempSum = tempSum + 1;
        }
        setSum(tempSum);
        //console.log(sum);
      }
    }
    if ((sum === 4 && timerExpire) || manualClose) {
      // change here
      //console.log(`Sum =  ${sum}`);
      if (hostProperties) {
        //console.log(hostProperties);
        const updates = {};
        updates[`sessionData/${roomId}/hostProperties/`] = {
          ...hostProperties,
          isOver: true,
        };

        Object.keys(roundData).forEach((pl, index) => {
          //const updates = {};
          if (!roundData[pl].isSubmit.status) {
            updates[`sessionData/${roomId}/state/${id}/${pl}`] = {
              ...roundData[pl],
              isSubmit: {
                choice: 1,
                status: true,
              },
            };
          }

          console.log({
            [pl]: {
              isSubmit: {
                choice: 1,
                status: true,
              },
            },
          });
        });

        update(ref(db), updates);
        navigate(`/host/results/${id}`);
      } else {
        console.log("No data available");
      }
      //console.log('round over');
      navigate(`/game/${roomId}/host/results/${id}`);
    }
  };
  const [properties, loading3] = useFirebaseRef(
    "sessions/" + roomId + "/properties"
  );

  if (Number(id) === 5) {
    multiplier.current = (
      <img
        src={multiplier3x}
        className="md:max-w-[65px] xs:max-w-[30px]"
        alt="3x"
      />
    );
  } else if (Number(id) === 8) {
    multiplier.current = (
      <img
        src={multiplier5x}
        className="md:max-w-[65px] xs:max-w-[30px]"
        alt="5x"
      />
    );
  } else if (Number(id) === 10) {
    multiplier.current = (
      <img
        src={multiplier10x}
        className="md:max-w-[65px] xs:max-w-[30px]"
        alt="10x"
      />
    );
  }

  useEffect(() => {
    //console.log(time)
    getTimer();
    console.log(loading4);
    console.log(roundData);
    //console.log(playerInfo);
    //.log(hostProperties);
    checkIfOver();
  }, [playerInfo, hostProperties]);

  const ruleHandler = () => {
    showRules(!rules);
  };

  useEffect(() => {
    get(child(ref(db), "sessionData/" + roomId + "/hostProperties"))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log(data);
          //console.log(data);
          const updates = {};
          updates["sessionData/" + roomId + "/hostProperties"] = {
            ...data,
            movetoWaitingRoom: false,
            nextRound: false,
            showScore: false,
            startTime: data.startTime ? data.startTime : new Date(),
            stopTimer: false,
            isOver: false,
          };
          update(ref(db), updates);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
        //toast.error('dsf')
      });
  }, []);
  const quitGame = () => {
    showQuit(true);
  };
  const reallyQuitGame = () => {
    const updates = {};
    updates[`sessions/${roomId}/properties`] = {
      ...properties,
      isOver: true,
    };
    update(ref(db), updates);
    navigate("/gameover");
  };

  const classes = makeStyles((theme) => ({
    transparentDialog: {
      backgroundColor: "transparent",
      width: "100%",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));

  return (
    <div>
      <div className="float-left ml-3 mt-2">
        <div className="flex flex-col items-start">
          <img
            src={mainLogo}
            alt="Logo"
            className="xs:max-w-[24vw] sm:max-w-[24vw] md:max-w-[10vw] lg:max-w-[10vw] max-h-[20vh]"
          />
          <div
            onClick={ruleHandler}
            className="text-white mt-2 z-20 cursor-pointer md:text-[18px] xs:text-[30px] flex align-middle items-center"
          >
            <InfoIcon /> &nbsp;
            <span className="xs:text-[14px] md:text-[18px] xs:max-w-[12%] md:block">
              Game Instructions
            </span>
          </div>
        </div>
      </div>
      <div className="float-right mr-3 mt-2">
        <div className="flex flex-col items-end text-white xs:text-[14px] md:text-[18px]">
          <div className="flex items-center cursor-pointer" onClick={quitGame}>
            End Game &nbsp;
            <CancelIcon />
          </div>

          <img
            className="xs:max-w-[12vw] sm:max-w-[12vw] md:max-w-[5vw] lg:max-w-[5vw] z-20 cursor-pointer"
            src={moneyBag}
            onClick={() => {
              showScore(true);
            }}
          />
          <b>Score Card</b>
        </div>
      </div>
      <div className="p-1 w-full absolute mt-4 flex flex-col h-full game">
        <div>
          <div className="relative flex justify-center items-center">
            <div className="absolute mt-4 flex justify-center items-center">
              <span className="absolute pt-3 text-[#fdc440] font-semibold">
                {id}
              </span>
              <img src={dayIcon} className="xs:max-h-[50px] md:max-h-[80px]" />
            </div>
            {Number(id) === 5 || Number(id) === 8 || Number(id) === 10 ? (
              <p className="relative rounded-full text-center mt-3 px-2 md:ml-[20vw] xs:ml-[30vw]">
                {multiplier.current}
              </p>
            ) : null}
          </div>
        </div>
        <br />
        <div className="flex flex-col items-center justify-center">
          <div>
            {!timerStatus && !hostPropertiesStatus && (
              <>
                <Timer
                  completed={timePercent}
                  sum={sum}
                  setTimer={setExpire}
                  round={id}
                  code={roomId}
                  startTime={startTime}
                  endTime={endTime}
                  timer={time}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex xs:flex-wrap md:flex-nowrap justify-center items-center">
          {!loading &&
            playerInfo &&
            Object.values(playerInfo).map((p, index) => (
              <div
                className="yo p-2 flex flex-col items-center"
                key={Math.random()}
              >
                {/* <FlashCard text={p.name} /> */}
                <div className="team-title">{p.name}</div>
                <ChoiceAndSubmit
                  choice={p.isSubmit.status ? p.isSubmit.choice : null}
                  toggle={!p.isSubmit.status ? p.isSelected.choice : null}
                  submitHostChoice={(num) => {
                    const confirmation = window.confirm(
                      "Are you sure to wanna select to " + p.name
                    );
                    if (confirmation) {
                      var playerId = Object.keys(playerInfo)[index];
                      //   console.log(playerInfo);
                      if (p) {
                        const updates = {};
                        updates[
                          "sessionData/" +
                            roomId +
                            "/state/" +
                            id +
                            "/" +
                            playerId
                        ] = {
                          ...p,
                          isSelected: {
                            choice: num,
                            status: true,
                          },
                          isSubmit: {
                            choice: num,
                            status: true,
                          },
                        };
                        //console.log(gameUser);
                        update(ref(db), updates);
                      } else {
                        console.log("No data available");
                      }
                    }
                  }}
                  time={time}
                  paused={mode}
                />
              </div>
            ))}
        </div>
        <br />
        <div className="results">
          {!loading && !loading1 ? (
            <center>
              <button
                onClick={() => {
                  checkIfOver(true);
                }}
                className={`bg-btn-bg-primary btn-lg mt-0 min-w-[90px] text-[20px] font-medium py-2.5 rounded-[15px] px-5 text-white inline-block bg-center bg-gradient-to-r from-[#ff914d] to-[#e8a04d]`}
              >
                {"Scores"}
              </button>
            </center>
          ) : null}
        </div>
        {score ? (
          <Dialog
            open={score}
            fullWidth
            PaperProps={{
              style: {
                backgroundImage: "url(../../../images/bg_new.png)",
                background:
                  "linear-gradient(to bottom right, #5de0e6, #0467af)",
                minWidth: "80%",
              },
            }}
            onClose={() => {
              showScore(false);
            }}
          >
            <div className="flex flex-col justify-center items-center h-screen">
              <div className="xs:text-[24px] md:text-[30px] text-center text-white">
                <b>Scores</b>
              </div>
              <div className="flex justify-center self-center w-max overflow-visible xs:w-full md:min-w-[700px]">
                {scoreData && playerData && !scoreLoading && !playerLoading ? (
                  <Scores
                    show={score}
                    scores={calculateScore(scoreData)}
                    players={playerData}
                  />
                ) : null}
              </div>
            </div>
          </Dialog>
        ) : null}
        {quit ? (
          <Dialog
            open={quit}
            onClose={() => {
              showQuit(false);
            }}
          >
            <div className="inline-flex justify-end w-full">
              <div className="inline-block">
                <button
                  onClick={() => showQuit(false)}
                  className="text-light text-2xl hover:text-light"
                >
                  &times;
                </button>
              </div>
            </div>
            <div className="self-center flex justify-center items-center flex-col ml-auto mr-auto">
              <div>
                <Heading
                  text={`Are you sure you want to End the game?`}
                  display={`text-4xl font-bold text-white`}
                />
              </div>
              <div>
                <Button
                  clickHandler={reallyQuitGame}
                  display={
                    "btn bg-btn-bg-primary btn-sm text-warning border-2 border-yellow mb-5"
                  }
                  text={"End"}
                />
              </div>
            </div>
          </Dialog>
        ) : null}
        {rules ? <Rules showRules={() => showRules(false)} /> : null}
      </div>
    </div>
  );
};

export default Discussion;
