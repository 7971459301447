import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";

import "./Waiting.css";
import { UserContext } from "../../context/context";
import { useNavigate, useParams } from "react-router-dom";
import { set, ref, update, get, child, onValue } from "firebase/database";
import { auth, database as db } from "../../firebase";
import useFirebaseRef from "../../utils/useFirebaseRef";
import { useTimer } from "react-timer-hook";
import { toast } from "react-hot-toast";
import Scores from "../../components/Scores/Scores";
import { calculateScore } from "../../utils/scoreHelper";
import logo from "../../images/logo_1.png";
import CancelIcon from "@mui/icons-material/Cancel";
import InfoIcon from "@mui/icons-material/Info";
import dayIcon from "../../images/day-icon.png";
import Heading from "../../components/Heading";
import { Dialog, DialogContent } from "@mui/material";
import Rules from "../Rules/Rules";
import { makeStyles } from "@mui/styles";
const Waiting = () => {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [roundNo, setRoundNo] = useState(0);
  const [startTime, setStartTime] = useState(new Date());
  const [active, setActive] = useState(false);
  const [counter, setCounter] = useState(180);
  const [timer, setTimer] = useState(120);
  const [quit, showQuit] = useState(false);

  const [waitingMsg, setWaitingMsg] = useState("");

  const [authUser, setAuthUser] = useState(false);
  const [rules, showRules] = useState(false);
  const [score, setScore] = useState(null);
  const { roomId } = useParams();
  const [playerData, playerLoading] = useFirebaseRef(
    `sessions/${roomId}/users`
  );
  const [scoreData, scoreLoading] = useFirebaseRef(
    `sessionData/${roomId}/state`,
    true
  );
  // const [skipRound, setSkipRound] = useState(false);

  // const { authUser } = useContext(AuthContext);
  // const { code } = useContext(CodeContext);

  const [disabled, setDisabled] = useState(false);
  const [sessionData, loading] = useFirebaseRef(`sessionData/${roomId}`);
  const [properties, loading1] = useFirebaseRef(
    "sessions/" + roomId + "/properties"
  );
  const [timerProps, timerLoading] = useFirebaseRef(
    "sessionData/" + roomId + "/timerState/"
  );

  const [time, setTime] = useState(new Date());
  const [player, loading2] = useFirebaseRef(`sessions/${roomId}/users`);

  const ruleHandler = () => {
    showRules(!rules);
  };

  const skipGameRound = () => {
    //const round = sessionData.state;
    Object.keys(player).forEach((pl, index) => {
      const updates = {};
      updates[`sessionData/${roomId}/state/${roundNo}/${pl}`] = {
        eye: false,
        indivScore: 0,
        isSelected: {
          choice: 0,
          status: true,
        },
        isSubmit: {
          choice: 0,
          status: true,
        },
        name: Object.values(player)[index].name,
      };
      update(ref(db), updates);
      setRoundNo(roundNo + 1);
      //console.log(updates);
    });
  };

  const getRoundNo = () => {
    get(child(ref(db), `sessionData/${roomId}/state`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          const res = Object.keys(snapshot.val()).at(-1);
          var snapshotObj = snapshot.val();
          console.log(snapshotObj)
          if (
            snapshotObj.length == 2 &&
            snapshotObj[0] == null &&
            !snapshotObj[1][Object.keys(snapshotObj[1])[0]].isSelected.status &&
            !snapshotObj[1][Object.keys(snapshotObj[1])[0]].isSubmit.status
          ) {
            setRoundNo(1);
          } else if (snapshotObj[snapshotObj.length - 1] &&
            !snapshotObj[snapshotObj.length - 1][Object.keys(snapshotObj[snapshotObj.length - 1])[0]]
              .isSelected.status &&
            !snapshotObj[snapshotObj.length - 1][Object.keys(snapshotObj[snapshotObj.length - 1])[0]]
              .isSubmit.status
          ) {
            setRoundNo(Number(res));
          } else {
            setRoundNo(Number(res) + 1);
          }
          // console.log("round no  "+ (Number(res) + 1))
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const movetoNextRound = () => {
    if (
      sessionData &&
      sessionData.hostProperties.nextRound &&
      (sessionData.hostProperties.isOver || roundNo >= 1)
    ) {
      const next = roundNo;
      const message = `Moving to day ${next}`;
      //	console.log(next);
      toast.success(message);
      if (!authUser) {
        setTimeout(() => {
          navigate(`/game/${roomId}/round/${next}`);
        }, 700);
      } else {
        navigate(`/game/${roomId}/round/${next}`);
      }
    }
  };

  useEffect(() => {
    getRoundNo();
    if (!scoreLoading && scoreData) setScore(calculateScore(scoreData));
  }, [roomId, sessionData]);

  useEffect(() => {
    console.log(timerProps);
    if (
      timerProps &&
      timerProps[roundNo] &&
      timerProps[roundNo].discussionStatus
    ) {
      setStartTime(new Date(timerProps[roundNo].discussionStartTime));
      var date = new Date(timerProps[roundNo].discussionStartTime);
      date.setSeconds(
        date.getSeconds() + parseInt(timerProps[roundNo].discussionTimer)
      );
      setTime(date);
      setActive(true);
      setDisabled(true);
    } else if (
      timerProps &&
      timerProps[roundNo] &&
      !timerProps[roundNo].discussionStatus
    ) {
      setActive(false);
      // setTime(new Date())
    }
  }, [timerProps, timerLoading, roundNo]);

  const quitGame = () => {
    showQuit(true);
  };

  const reallyQuitGame = () => {
    const updates = {};
    updates[`sessions/${roomId}/properties`] = {
      ...properties,
      isOver: true,
    };
    update(ref(db), updates);
    navigate("/gameover");
  };

  useEffect(() => {
    movetoNextRound();
    //console.log(roundNo);
  }, [sessionData, loading]);
  useEffect(() => {
    if (properties && user) {
      if (!authUser && properties.isOver) {
        navigate(`/gameover`);
      }
      if (properties.host.userID === user.id) {
        setAuthUser(true);
      } else {
        setAuthUser(false);
      }
    }
  }, [properties, user, loading1]);

  const startGame = () => {
    update(ref(db, "/sessionData/" + roomId + "/timerState/" + roundNo), {
      timer: timer,
    });
    Object.keys(player).forEach((pl, index) => {
      const updates = {};
      updates[`sessionData/${roomId}/state/${roundNo}/${pl}`] = {
        eye: false,
        indivScore: 0,
        isSelected: {
          choice: 0,
          status: false,
        },
        isSubmit: {
          choice: 0,
          status: false,
        },
        name: Object.values(player)[index].name,
      };
      update(ref(db), updates);
      //console.log(updates);
    });
    get(child(ref(db), `sessionData/${roomId}/hostProperties`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          //	console.log(snapshot.val());
          const res = snapshot.val();
          const updates = {};
          updates[`sessionData/${roomId}/hostProperties`] = {
            ...res,
            roundNo:roundNo,
            movetoWaitingRoom: false,
            nextRound: true,
            showScore: false,
            startTime: new Date(),
            stopTimer: false,
            isOver: false,
          };
          update(ref(db), updates);
          // navigate(`/game/${roomId}/round/${roundNo}`);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const endGame = () => {
    const updates = {};
    updates[`sessions/${roomId}/properties`] = { ...properties, isOver: true };
    update(ref(db), updates);
    navigate("/gameover");
  };

  function MyTimer({ expiryTimestamp }) {
    const {
      seconds,
      minutes,
      hours,
      days,
      isRunning,
      start,
      pause,
      resume,
      restart,
    } = useTimer({
      expiryTimestamp,
      onExpire: () => {
        setActive(false);
        setDisabled(false);
        if (timerProps) {
          update(ref(db, "/sessionData/" + roomId + "/timerState/" + roundNo), {
            ...timerProps[roundNo],
            discussionEndTime: new Date(),
            discussionStatus: false,
          });
        }
      },
    });
    useEffect(() => {
      if (!active && !disabled) {
        pause();
        //console.log(`paused ${disabled}`);
      }
    }, []);

    const getTimerPercentage = () => {
      const currentTime = new Date();
      const totalDuration = expiryTimestamp - startTime;
      const elapsedTime = currentTime - startTime;
      const percentage = (elapsedTime / totalDuration) * 100;
      // console.log(expiryTimestamp);
      // console.log(startTime);
      return parseFloat(Math.min(100, Math.max(0, percentage)).toFixed(2)); // Clamp the percentage between 0 and 100
    };

    const timerPercentage = getTimerPercentage();
    return (
      <button
        style={{ textAlign: "center" }}
        onClick={() => {
          if (active) {
            setActive(false);
            // console.log(`timer is pause`);
            //pause()
          } else {
            setActive(true);
            setDisabled(true);
            setStartTime(new Date());
            //console.log(`timer is play`);
            //  resume()
          }
        }}
        className="text-warning self-center text-4xl bg-transparent rounded-sm px-3 py-3"
        disabled={disabled}
      >
        <div class="w-full h-4 mb-4 bg-white rounded-full min-w-[35vw] border-[#ff914d]">
          <div
            class="h-4 bg-[#ff914d] rounded-full"
            style={{ width: `${timerPercentage || 0}%` }}
          ></div>
        </div>
        <div style={{ fontSize: "40px" }}>
          <span>{minutes}</span>:<span>{seconds}</span>
        </div>
      </button>
    );
  }

  return (
    <div>
      <div className="absolute left-3">
        <div
          onClick={ruleHandler}
          className="text-white mt-2 z-20 cursor-pointer md:text-[18px] xs:text-[30px] flex align-middle items-center"
        >
          <InfoIcon /> &nbsp;
          <span className="xs:hidden md:block">Game Instructions</span>
        </div>
      </div>
      <div className="absolute right-3 top-2">
        {auth.currentUser && !auth.currentUser.isAnonymous && (
          <button
            className={`btn-lg min-w-[90px] text-white inline-block bg-center bg-gradient-to-r from-[#ffde59] to-[#ff914d] mt-1`}
            onClick={() => {
              auth.signOut();
              navigate("/host");
            }}
          >
            {"Logout"}
          </button>
        )}
      </div>
      <div className="flex flex-col items-center h-screen overflow-y-auto">
        <div className="pt-2 mb-3 top-0">
          <img
            src={logo}
            alt="Logo"
            className="mx-auto xs:w-[20vw] sm:w-[20vw] md:w-[12vw] lg:w-[12vw]"
          />
        </div>
        <div className="bg-card bg-no-repeat bg-cover">
          {(roundNo === 5 || roundNo === 8 || roundNo === 10) && authUser ? (
            <div className="flex flex-col w-full justify-center items-center pt-3">
              <h1 className="text-2xl text-warning font-bold px-4">
                {!active
                  ? "Start the discussion for team leaders!"
                  : "Discussion started!"}
              </h1>
              <br />
              {!active && (
                <div className="relative flex justify-center items-center">
                  <label
                    htmlFor="timer"
                    className="text-white font-semibold md:text-xl xs-mobile:text-lg text-center"
                  >
                    Discussion Time &nbsp;
                  </label>
                  <input
                    type="number"
                    onChange={(e) => {
                      setCounter(e.target.value);
                    }}
                    // placeholder="Set time"
                    value={counter}
                    className="inp bg-gradient-to-r from-[#94def3] to-[#0a7cb0] text-white font-semibold text-center mx-auto"
                    name="timer"
                  />
                </div>
              )}
              {active && <MyTimer expiryTimestamp={time} active={active} />}
              {!active && (
                <button
                  onClick={() => {
                    var date = new Date();
                    setStartTime(new Date());
                    setTime(
                      date.setSeconds(date.getSeconds() + parseInt(counter))
                    );
                    setActive(true);
                    setDisabled(true);
                    update(
                      ref(
                        db,
                        "/sessionData/" + roomId + "/timerState/" + roundNo
                      ),
                      {
                        discussionTimer: counter,
                        discussionStartTime: new Date().toString(),
                        discussionStatus: true,
                      }
                    );
                  }}
                  className="next-btn btn-lg ml-5 my-3 px-4 font-medium text-base py-2"
                >
                  Start Discussion
                </button>
              )}
              {active && (
                <button
                  onClick={() => {
                    setActive(false);
                    setDisabled(false);
                    update(
                      ref(
                        db,
                        "/sessionData/" + roomId + "/timerState/" + roundNo
                      ),
                      {
                        ...timerProps[roundNo],
                        discussionEndTime: new Date(),
                        discussionStatus: false,
                      }
                    );
                  }}
                  className="next-btn btn-lg ml-5 my-3 px-4 font-medium text-base py-2"
                >
                  End Discussion
                </button>
              )}
            </div>
          ) : null}
          {authUser && !active ? (
            <div className="grid justify-center items-center xs:grid-cols-1 md:grid-cols-3 xs:gap-4 md:gap-2 mt-5">
              <div className="relative flex justify-center items-center">
                <span className="absolute pt-3 text-[#fdc440] font-semibold">
                  {roundNo}
                </span>
                <img src={dayIcon} style={{ maxHeight: "80px" }} />
              </div>
              <label
                htmlFor="timer"
                className="text-white font-semibold md:text-xl xs-mobile:text-lg text-center"
              >
                Set Timer
              </label>
              <input
                type="number"
                onChange={(e) => setTimer(e.target.value)}
                // placeholder="Set time"
                value={timer}
                className="inp bg-gradient-to-r from-[#94def3] to-[#0a7cb0] text-white font-semibold text-center mx-auto"
                name="timer"
              />
            </div>
          ) : null}
          {authUser ? (
            roundNo < 11 && !active ? (
              <div className="flex flex-row justify-center items-center mt-5 py-10 px-10 w-full">
                <div>
                  <button
                    onClick={skipGameRound}
                    className="skip-btn btn-lg px-9 font-medium text-base py-2"
                  >
                    SKIP
                  </button>
                </div>
                <div>
                  <button
                    onClick={startGame}
                    className="next-btn btn-lg ml-5 px-9 font-medium text-base py-2"
                  >
                    START
                  </button>
                </div>
              </div>
            ) : null
          ) : (
            <div className="flex flex-col">
              <h1 className="text-white text-xl font-bold px-10 pt-0 text-center">
                Waiting for the host to start the next round
              </h1>
              <br />
              {active && <MyTimer expiryTimestamp={time} active={active} />}
              <div
                style={{
                  background: "linear-gradient(to right,#94def3, #0a7cb0)",
                  borderRadius: "10px",
                }}
                className="flex drop-shadow-sm justify-center self-center w-full md:min-w-[800px] ml-auto mr-auto relative"
              >
                {playerData &&
                !playerLoading &&
                scoreData &&
                roundNo != 1 &&
                !scoreLoading ? (
                  <Scores show={false} scores={score} players={playerData} />
                ) : null}
              </div>
            </div>
          )}
          {quit ? (
            <Dialog
              open={quit}
              onClose={() => {
                showQuit(false);
              }}
            >
              <div className="inline-flex justify-end w-full">
                <div className="inline-block">
                  <button
                    onClick={() => showQuit(false)}
                    className="text-light text-2xl hover:text-light"
                  >
                    &times;
                  </button>
                </div>
              </div>
              <div className="self-center flex justify-center items-center flex-col ml-auto mr-auto">
                <div>
                  <Heading
                    text={`Are you sure you want to End the game?`}
                    display={`text-4xl font-bold text-white`}
                  />
                </div>
                <div>
                  <Button
                    clickHandler={reallyQuitGame}
                    display={
                      "btn bg-btn-bg-primary btn-sm text-warning border-2 border-yellow mb-5"
                    }
                    text={"End"}
                  />
                </div>
              </div>
            </Dialog>
          ) : null}
          {rules ? <Rules showRules={() => showRules(false)} /> : null}
        </div>
      </div>
    </div>
  );
};

export default Waiting;
