import React from "react";
import HomeNavbar from "../../components/HomeNavBar/HomeNavbar";
// import Flashcard from '../../components/Flashcard/Flashcard';
import logo from "../../images/logo_1.png";
import "../../components/Flashcard/Flashcard.css";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";
const Intro = () => {
  const navigate = useNavigate();
  return (
    <div>
      {auth.currentUser && !auth.currentUser.isAnonymous && (
        <div className="right-2 top-1 absolute z-1000">
          <button
            className={`btn-lg min-w-[90px] text-white inline-block bg-center bg-gradient-to-r from-[#ffde59] to-[#ff914d] mt-1`}
            onClick={() => {
              auth.signOut();
              navigate("/host");
            }}
          >
            {"Logout"}
          </button>
        </div>
      )}
      <div className="flex items-center justify-center p-3">
        <div>
          <img
            src={logo}
            alt="Logo"
            className="mx-auto xs:w-[55vw] sm:w-[36vw] md:w-[16vw] lg:w-[16vw]"
          />
          <HomeNavbar />
        </div>
      </div>
    </div>
  );
};

export default Intro;
