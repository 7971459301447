import React, { useEffect, useState } from "react";
import { auth } from "../../firebase";
import LoginScreen from "../../screens/Host/LoginScreen/LoginScreen";
import Intro from "../../screens/Intro/Intro";
import logo from "../../images/logo_1.png";

const AuthGuard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (isLoading) {
    return (
      <div className="mx-auto my-auto max-w-[30vw]">
        <img src={logo} alt="Logo" />
      </div>
    );
  }

  return isLoggedIn ? <Intro /> : <LoginScreen />;
};

export default AuthGuard;
