import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { CodeContext, UserContext } from "../../../context/context";

import Button from "../../../components/Button/Button";
import DeckIcons from "../../../components/DeckIcons/DeckIcons";
import FishOptions from "../../../components/FishOptions/FishOptions";
import FlashCard from "../../../components/Flashcard/Flashcard";
import Icons from "../../../components/Icons/Icons";
import Modal from "../../../components/Modal/Modal";
import Timer from "../../../components/Timer/Timer";

import Fish1 from "../../../images/Fish1-new.png";
import Fish2 from "../../../images/Fish2-new.png";
import three from "../../../images/three.png";
import five from "../../../images/five.png";
import ten from "../../../images/ten.png";
import "./GameRounds.css";
import dayIcon from "../../../images/day-icon.png";
import mainLogo from "../../../images/logo_1.png";
import moneyBag from "../../../images/money-bag.png";
import multiplier3x from "../../../images/3x_multiplier.png";
import multiplier5x from "../../../images/5x_multiplier.png";
import multiplier10x from "../../../images/10x_multiplier.png";
import cardBg from "../../../images/bg_new.png";
import "../../../components/Global.css";

import { set, ref, update, get, child } from "firebase/database";
import { database as db } from "../../../firebase";
import useFirebaseRef from "../../../utils/useFirebaseRef";
import InfoIcon from "@mui/icons-material/Info";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
} from "@mui/material";
import Rules from "../../Rules/Rules";
import { makeStyles, withStyles } from "@mui/styles";
import { calculateScore } from "../../../utils/scoreHelper";
import Scores from "../../../components/Scores/Scores";

const GameRounds = () => {
  // const timeP = useRef(120);
  const { roomId, id } = useParams();
  const navigate = useNavigate();
  let multiplier = useRef(0);
  const [time, setTime] = useState(120);
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [timePercent, setTimePercent] = useState();
  const [choice, setChoice] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [active, setActive] = useState([false, false]);
  const [score, showScore] = useState(false);
  const [timerStatus, setTimerStatus] = useState(true);
  const [hostPropertiesStatus, setHostPropertiesStatus] = useState(true);
  const [rules, showRules] = useState(false);

  const user = useContext(UserContext);
  // const { code } = useContext(CodeContext);
  const userID = user.id;

  const [scoreData, scoreLoading] = useFirebaseRef(
    `sessionData/${roomId}/state`,
    true
  );

  const [playerData, playerLoading] = useFirebaseRef(
    `sessions/${roomId}/users`
  );

  const [isOver, loading] = useFirebaseRef(
    `sessionData/${roomId}/hostProperties/isOver`
  );

  const [hostProperties, hostPropertiesLoading] = useFirebaseRef(
    `sessionData/${roomId}/hostProperties`
  );

  const [properties, loading3] = useFirebaseRef(
    "sessions/" + roomId + "/properties"
  );

  const [users, loading1] = useFirebaseRef(`users/${userID}`);
  const [gameUser, loading2] = useFirebaseRef(
    "sessionData/" + roomId + "/state/" + id + "/" + userID + "/"
  );

  const [userState, loading4] = useFirebaseRef(
    "sessionData/" + roomId + "/state/"
  );
  const checkIfOver = () => {
    if (isOver && !loading) {
      setTimeout(() => {
        navigate(`/game/${roomId}/player/results/${id}`);
      }, 700);
    }
  };
  useEffect(() => {
    getTimer();
  }, []);
  useEffect(() => {
    if (!loading3 && properties.isOver) {
      navigate("/gameover");
    }
  }, [loading3, properties]);
  useEffect(() => {
    checkIfOver();
  }, [isOver, loading]);
  useEffect(() => {
    if(hostProperties && !timerStatus){
      setStartTime(hostProperties['startTime']?new Date(hostProperties['startTime']):new Date())
      var tempEndTime = hostProperties['startTime']?new Date(hostProperties['startTime']):new Date()
      tempEndTime.setSeconds(tempEndTime.getSeconds()+parseInt(time))
      setEndTime(tempEndTime)
      setHostPropertiesStatus(false)
    }
  }, [hostProperties, hostPropertiesLoading, timerStatus]);
  useEffect(() => {
    if (id > 10) {
      navigate(`/gameover`);
    } else if (roomId && userID) {
      console.log(users);
      if (users) {
        const name = users.name;
        //console.log(name);
        set(
          ref(db, "sessionData/" + roomId + "/hostProperties/eye/" + userID),
          {
            isTrue: false,
          }
        );
        const playerRef = ref(
          db,
          "sessionData/" + roomId + "/state/" + id + "/" + userID
        );
        //console.log(gameUser);

        if (gameUser)
          set(playerRef, {
            eye: gameUser.eye,
            name,
            indivScore: gameUser.indivScore,
            isSelected: {
              status: gameUser.isSelected.status,
              choice: gameUser.isSelected.choice,
            },
            isSubmit: {
              status: gameUser.isSubmit.status,
              choice: gameUser.isSubmit.choice,
            },
          });
        else {
          set(playerRef, {
            eye: false,
            name,
            indivScore: 0,
            isSelected: {
              status: false,
              choice: 0,
            },
            isSubmit: {
              status: false,
              choice: 0,
            },
          });
        }
      } else {
        console.log("No data available");
      }

      setChoice(gameUser ? gameUser.isSelected.choice : 0);
      setDisabled(gameUser ? gameUser.isSubmit.status : false);
      gameUser && gameUser.isSelected.choice !== 0
        ? gameUser.isSelected.choice === 1
          ? setActive([true, false])
          : setActive([false, true])
        : setActive([false, false]);
    }
  }, [id, users, loading1]);
  const getTimer = () => {
    get(child(ref(db), `sessionData/${roomId}/timerState/${id}`))
      .then((snapshot) => {
        console.log(snapshot.val());
        if (snapshot.val().timer) {
          const res = snapshot.val().timer;
          console.log(res);
          /// console.log(res);
          setTime(res);
        } else {
          console.log("No data available");
        }
        setTimerStatus(false);
      })
      .catch((error) => {
        console.error(error);
        setTimerStatus(false);
      });
  };
  const [totalScore, setScore] = useState(0);
  const selectChoice = (num) => {
    num === 1 ? setActive([true, false]) : setActive([false, true]);
    setChoice(num);
    //console.log('choice made', num);

    if (gameUser && !loading2) {
      const updates = {};
      updates["sessionData/" + roomId + "/state/" + id + "/" + userID] = {
        ...gameUser,
        isSelected: {
          choice: num,
          status: true,
        },
      };
      //console.log(gameUser);
      update(ref(db), updates);
    } else {
      console.log("No data available");
    }
  };

  const ruleHandler = () => {
    showRules(!rules);
  };

  const submitChoice = () => {
    //cearInterval(timerID);
    if (gameUser && !loading2 && choice > 0) {
      const updates = {};
      updates["sessionData/" + roomId + "/state/" + id + "/" + userID] = {
        ...gameUser,
        isSubmit: {
          choice,
          status: true,
        },
      };
      //console.log(gameUser);
      update(ref(db), updates);
      setDisabled(true);
      Number(choice) === 1
        ? setActive([true, false])
        : setActive([false, true]);
    } else {
      console.log("No data available");
    }
  };

  const captureClick = () => {
    if (disabled) {
      //console.log(choice);
    } else {
      setActive([false, false]);
    }
  };

  if (Number(id) === 5) {
    multiplier.current = (
      <img
        src={multiplier3x}
        className="md:max-w-[70px] xs:max-w-[40px]"
        alt="3x"
      />
    );
  } else if (Number(id) === 8) {
    multiplier.current = (
      <img
        src={multiplier5x}
        className="md:max-w-[70px] xs:max-w-[40px]"
        alt="5x"
      />
    );
  } else if (Number(id) === 10) {
    multiplier.current = (
      <img
        src={multiplier10x}
        className="md:max-w-[65px] xs:max-w-[30px]"
        alt="10x"
      />
    );
  }
  useEffect(() => {
    if (!loading4 && userState) {
      Object.values(userState).map((state, ind) => {
        const tmp = totalScore + state[userID].indivScore;
        //console.log(tmp );
        setScore(tmp);
      });
    }
  }, [loading4, userState]);

  return (
    <div className="100vh">
      <div className="float-left ml-3 mt-2">
        <div className="flex flex-col items-start">
          <img
            src={mainLogo}
            alt="Logo"
            className="xs:max-w-[24vw] sm:max-w-[24vw] md:max-w-[10vw] lg:max-w-[10vw] max-h-[20vh]"
          />
          <div
            onClick={ruleHandler}
            className="text-white mt-2 cursor-pointer md:text-[18px] xs:text-[30px] flex align-middle items-center"
          >
            <InfoIcon /> &nbsp;
            <span className="xs:text-[14px] md:text-[18px] xs:max-w-[12%] md:block z-20">
              Game Instructions
            </span>
          </div>
        </div>
      </div>
      <div className="float-right mr-3 mt-2">
        <div className="flex flex-col items-end text-white xs:text-[14px] md:text-[18px]">
          <b>Team</b>
          <div className="my-2 text-white text-center sm:text-[14px] xs:text-[14px] md:text-[18px] lg:text-[18px] bg-gradient-to-r from-[#ffde59] to-[#ff914d] px-2 xs:min-w-[10vw] md:min-w-[10vw] xs:py-2 md:px-4 font-bold rounded-sm cursor-default pointer-events-none">
            <span>{gameUser?.name}</span>
          </div>
          <b>Score Card</b>
          <img
            className="xs:max-w-[12vw] sm:max-w-[12vw] md:max-w-[5vw] lg:max-w-[5vw] cursor-pointer z-20"
            src={moneyBag}
            onClick={() => {
              showScore(true);
            }}
          />
        </div>
      </div>
      <div className="p-1 w-full absolute mt-4 flex flex-col h-full game">
        <div>
          <div className="relative flex justify-center items-center">
            <div className="absolute mt-4 flex justify-center items-center">
              <span className="absolute pt-3 text-[#fdc440] font-semibold">
                {id}
              </span>
              <img src={dayIcon} className="xs:max-h-[50px] md:max-h-[80px]" />
            </div>
            {Number(id) === 5 || Number(id) === 8 || Number(id) === 10 ? (
              <p className="relative rounded-full text-center mt-3 px-2 py-2 md:ml-[20vw] xs:ml-[30vw]">
                {multiplier.current}
              </p>
            ) : null}
          </div>
        </div>
        <br />
        <div className="flex flex-col items-center justify-center mt-2">
          <div>
            {!timerStatus && !hostPropertiesStatus && (
              <Timer
                completed={timePercent}
                round={id}
                startTime={startTime}
                endTime={endTime}
                timer={time}
                userID={userID}
                code={roomId}
              />
            )}
          </div>
        </div>
        <div
          className="flex md:flex-nowrap justify-center items-center overflow-y-hidden"
          onClickCapture={() => captureClick()}
        >
          <div className={`p-3`}>
            <FishOptions
              fishes={Fish1}
              active={active[0]}
              SelectChoice={disabled ? null : selectChoice}
              id={1}
            />
          </div>
          <div className={`p-3`}>
            <FishOptions
              fishes={Fish2}
              active={active[1]}
              SelectChoice={disabled ? null : selectChoice}
              id={2}
            />
          </div>
        </div>
        {disabled ? (
          <button
            className="text-warning bg-btn-bg-primary btn-lg bg-center w-25 self-center disabled:opacity-50 cursor-default"
            disabled
          >
            Submit
          </button>
        ) : (
          <div className="self-center join-btn">
            <Button text="Submit" clickHandler={submitChoice} />
          </div>
        )}

        <div className="absolute md:top-1/4 md:right-12 xs-mobile:top-44 xs-mobile:right-8"></div>
        {/* <DeckIcons /> */}
        {score ? (
          <Dialog
            open={score}
            fullWidth
            PaperProps={{
              style: {
                backgroundImage: "url(../../../images/bg_new.png)",
                background:
                  "linear-gradient(to bottom right, #5de0e6, #0467af)",
                minWidth: "80%",
              },
            }}
            onClose={() => {
              showScore(false);
            }}
          >
            <div className="flex flex-col justify-center items-center h-screen">
              <div className="xs:text-[24px] md:text-[30px] text-center text-white">
                <b>Scores</b>
              </div>
              <div className="tables flex justify-center self-center xs-mobile:w-full md:w-5/6 ml-auto mr-auto">
                {scoreData && playerData && !scoreLoading && !playerLoading ? (
                  <Scores
                    show={score}
                    scores={calculateScore(scoreData)}
                    players={playerData}
                  />
                ) : null}
              </div>
            </div>
          </Dialog>
        ) : null}
        {rules ? <Rules showRules={() => showRules(false)} /> : null}
      </div>
    </div>
  );
};

export default GameRounds;
