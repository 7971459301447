import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { CodeContext, UserContext } from "../../../context/context";

import Button from "../../../components/Button/Button";

import "./PlayerScreen.css";

import { auth, database as db } from "../../../firebase";
import { ref, update, set, get, child } from "firebase/database";

import { toast } from "react-hot-toast";
import useFirebaseRef from "../../../utils/useFirebaseRef";

const PlayerScreen = () => {
  const Navigate = useNavigate();
  const user = useContext(UserContext);
  const [code, setCode] = useState("");
  const [playerName, setPlayerName] = useState("");
  const [playerMailId, setPlayerMailId] = useState("");
  const [session, loading] = useFirebaseRef(`sessions/${code}`);

  const enterGame = async () => {
    // check for room in rdb
    if (code.length && playerName.length) {
      if (session) {
        const currentUser = auth.currentUser;
        currentUser.email = playerMailId;
        currentUser.displayName = playerName;
        auth.updateCurrentUser(currentUser);
        const newUser = {
          name: playerName,
          role: "player",
          mailId: playerMailId,
          user: {
            uid: currentUser.uid,
            displayName: currentUser.displayName,
            email: currentUser.email,
          },
          rounds: [],
        };
        window.localStorage.setItem("roomId",code)
        window.localStorage.setItem("uid",currentUser.uid)
        const sessionUsers = await get(
          child(ref(db), `sessions/${code}/users/`)
        );
        var existStatus = false;
        var existMessage = "";
        if(sessionUsers.val()){
          sessionUsers.forEach((e)=>{
            if(e.exists()){
              var val = e.val();
              // console.log(val)
              if(val.mailId && val.mailId.toLowerCase()==playerMailId.toLowerCase()){
                existMessage = "Email Id already inside the lobby";
                existStatus = true;
                return;
              }
              if(val.name && val.name.toLowerCase()==playerName.toLowerCase()){
                existMessage = "Name already Exists";
                existStatus = true;
                return;
              }
            }
          })
        }
        if(sessionUsers.val() == null || sessionUsers.size<4){
          // console.log("here")
          if(existStatus){
            toast.error(existMessage);
          }else{
            const updates = {};
            updates[`sessions/${code}/users/` + user.id] = newUser;
            updates[`users/` + user.id] = {
              name: playerName,
            };
            update(ref(db), updates);
            set(ref(db, "sessionData/" + code + "/hostProperties/eye/" + user.id), {
              isTrue: false,
            });
            set(ref(db, "sessionData/" + code + "/state/1/" + user.id), {
              eye: false,
              name: playerName,
              indivScore: 0,
              isSelected: {
                status: false,
                choice: 0,
              },
              isSubmit: {
                status: false,
                choice: 0,
              },
            });
    
            Navigate(`/lobby/${code}`);
          }
        }else{
          let updateStatus = false;
          sessionUsers.forEach((e)=>{
            var val = e.val();
            if(val.mailId && val.mailId.toLowerCase()==playerMailId.toLowerCase()){
              currentUser.uid = val.user.uid;
              auth.updateCurrentUser(currentUser)
              window.localStorage.setItem("roomId",code)
              window.localStorage.setItem("uid",val.user.uid)
              window.localStorage.setItem("playerName",playerName)
              updateStatus = true;
            }
          })
          if(!updateStatus){
            toast.error("Not Allowed")
          }else{
            const hostProperties = await get(child(ref(db), `sessionData/${code}/hostProperties`));
            const properties = await get(child(ref(db), `sessions/${code}/properties`));
            if(properties.exists()){
              const propertiesVal = properties.val();
              if(propertiesVal.isOver){
                Navigate("/gameover");
              }
            }
            if(hostProperties.exists()){
              const hostPropertiesVal = hostProperties.val()
              if(hostPropertiesVal.movetoWaitingRoom || (hostPropertiesVal.nextRound && !hostPropertiesVal.roundNo)){
                Navigate(`/game/${code}/waiting`)
              }else if(hostPropertiesVal.isOver && hostPropertiesVal.roundNo){
                Navigate(`/game/${code}/player/results/${hostPropertiesVal.roundNo}`);
              }else if(hostPropertiesVal.roundNo){
                Navigate(`/game/${code}/round/${hostPropertiesVal.roundNo}`);
              }else{
                Navigate(`/lobby/${code}/waiting`);
              }
            }else{
              Navigate(`/lobby/${code}/waiting`);
            }
          }
        }
      } else {
        console.log("No data available");
        toast.error("Lobby doesnot exist");
      }
      //console.log('enter game');
    }
  };

  return (
    <div className="w-full flex flex-col bg-card bg-no-repeat bg-cover bg-blend-screen shadow-lg rounded-lg px-8 pt-6 pb-8 mt-10  h-full text-white justify-center items-center host-btn">
      <div className="mb-4">
        <label
          htmlFor="Code"
          className="block text-white text-base font-bold mb-2 py-1 "
        >
          Room Code :
        </label>
        <input
          type="text"
          placeholder="Eg:12345"
          value={code}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          onChange={(e) => setCode(e.target.value)}
          required
        ></input>
      </div>
      <div className="mb-4">
        <label
          htmlFor="emailId"
          className="block text-white text-base font-bold mb-2 py-1 "
        >
          Email Id :
        </label>
        <input
          type="text"
          placeholder="example@example.com"
          value={playerMailId}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          onChange={(e) => setPlayerMailId(e.target.value)}
          required
        ></input>
      </div>
      <div className="mb-0">
        <label
          className="block text-white text-base font-bold mb-2 py-1"
          htmlFor="Code"
        >
          Team Name :
        </label>
        <input
          type="text"
          placeholder="Eg:David"
          value={playerName}
          onChange={(e) => {
            if (e.target.value.length < 13) {
              setPlayerName(e.target.value);
            }
          }}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        ></input>
      </div>
      <div className="self-center join-btn">
        {!loading ? <Button text="Join" clickHandler={enterGame} /> : null}
      </div>
    </div>
  );
};

export default PlayerScreen;
