import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FlashCard from "../../../components/Flashcard/Flashcard";
import Scores from "../../../components/Scores/Scores";

import "./Scoreboard.css";
import moneyBag from "../../../images/money-bag.png";

import { CodeContext } from "../../../context/context";

import useFirebaseRef from "../../../utils/useFirebaseRef";
import { calculateScore } from "../../../utils/scoreHelper";
import Rules from "../../Rules/Rules";
import mainLogo from "../../../images/logo_1.png";
import InfoIcon from "@mui/icons-material/Info";

const Scoreboard = () => {
  const navigate = useNavigate();
  const { roomId, id } = useParams();
  const [rules, showRules] = useState(false);
  // const { code } = useContext(CodeContext);
  const [playerData, loading] = useFirebaseRef(`sessions/${roomId}/users`);
  const [scoreData, loading1] = useFirebaseRef(
    `sessionData/${roomId}/state`,
    true
  );
  const [hostProperties, loading2] = useFirebaseRef(
    `sessionData/${roomId}/hostProperties`
  );

  const [session, sessionLoading] = useFirebaseRef(
    "sessions/" + roomId + "/properties"
  );

  const [show, setShow] = useState(false);

  const waitingRoom = () => {
    if (hostProperties && hostProperties.movetoWaitingRoom) {
      //console.log('move to waitin');
      navigate(`/game/${roomId}/waiting`);
    }
  };
  
  const ruleHandler = () => {
    showRules(!rules);
  };
  
  useEffect(() => {
    if (!sessionLoading && session.isOver) {
      navigate("/gameover");
    }
  }, [sessionLoading, session]);

  useEffect(() => {
    //console.log(playerData);
    //console.log(scoreData);
    waitingRoom();
  }, [loading2, hostProperties]);
  return (
    <div>
      <div className="absolute left-3 top-2">
        <div className="flex flex-col items-start">
          <img
            src={mainLogo}
            alt="Logo"
            className="xs:max-w-[24vw] sm:max-w-[24vw] md:max-w-[10vw] lg:max-w-[10vw] max-h-[20vh]"
          />
          <div
            onClick={ruleHandler}
            className="text-white mt-2 z-20 cursor-pointer md:text-[18px] xs:text-[30px] flex align-middle items-center"
          >
            <InfoIcon /> &nbsp;
            <span className="xs:hidden md:block max-w-[25px]">
              Game Instructions
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-center items-center h-full">
        <div className="flex justify-center items-center">
          <img src={moneyBag} className="max-w-[65px]" /> &nbsp;
          <span className="text-[32px] text-[#996633]">Score Card</span>
        </div>
        <br />
        <div
          style={{
            background: "linear-gradient(to right,#94def3, #0a7cb0)",
            borderRadius: "10px",
          }}
          className="flex drop-shadow-sm justify-center self-center xs-mobile:w-full md:w-4/6 ml-auto mr-auto relative"
        >
          {playerData && !loading && scoreData && !loading1 ? (
            <Scores
              show={show}
              scores={calculateScore(scoreData)}
              players={playerData}
            />
          ) : null}
        </div>
      </div>
      {rules ? <Rules showRules={() => showRules(false)} /> : null}
    </div>
  );
};

export default Scoreboard;
