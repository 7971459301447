import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";

import "./GenerateLink.css";
import logo from "../../../images/logo_1.png";
import InfoIcon from "@mui/icons-material/Info";

import {
  UserContext,
  CodeContext,
  AuthContext,
} from "../../../context/context";
import Settings from "../Settings/Settings";
import Rules from "../../Rules/Rules";
import Refresh from "../../../images/refresh.png";
import SettingIcon from "../../../images/settings.png";

import Heading from "../../../components/Heading";
import Button from "../../../components/Button/Button";
import FlashCard from "../../../components/Flashcard/Flashcard";
import Modal from "../../../components/Modal/Modal";
import Icons from "../../../components/Icons/Icons";
import NavComponent from "../../../components/NavComponent";

import { auth, database as db } from "../../../firebase";
import { ref, set, get, child } from "firebase/database";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const GenerateLink = () => {
  const navigate = useNavigate()
  const user = useContext(UserContext);
  const [code, setCode] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [settings, showSettings] = useState(false);
  const [rules, showRules] = useState(false);
  const [timer, setTimer] = useState(120);

  function generateCode(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const newRoom = () => {
    // Make 5 attempts to create a unique game session
    const userID = user.id;
    //console.log(user.id);
    setWaiting(true);
    let attempts = 0;
    while (attempts < 5) {
      const gameId = generateCode(7);
      setCode(gameId);
      if (checkIfExists(code)) {
        ++attempts;

        continue;
      } else {
        set(ref(db, "sessions/" + code), {
          properties: {
            timer,
            isStarted: false,
            isOver: false,
            host: {
              userID,
              name: "Logan",
            },
          },
        });
        set(ref(db, "users/" + userID), {
          name: "logan",
        });

        //console.log('host added');
        setWaiting(false);
        return;
      }
    }
    // Unsuccessful game creation
    setWaiting(false);
    alert("Error: Could not find an available game ID.");
  };

  const checkIfExists = (code) => {
    const dbRef = ref(db);
    //console.log(code);
    get(child(dbRef, `sessions/${code}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          return true;
        } else {
          return false;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    setCode(generateCode(7));
  }, []);

  const ruleHandler = () => {
    showRules(!rules);
  };

  const classes = makeStyles((theme) => ({
    transparentDialog: {
      backgroundColor: "transparent",
      width: "100%",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));
  //console.log(code);
  return (
    <div>
      {auth.currentUser && !auth.currentUser.isAnonymous && (
        <div className="right-2 top-1 absolute z-1000">
          <button
            className={`btn-lg min-w-[90px] text-white inline-block bg-center bg-gradient-to-r from-[#ffde59] to-[#ff914d] mt-1`}
            onClick={() => {
              auth.signOut();
              navigate("/host");
            }}
          >
            {"Logout"}
          </button>
        </div>
      )}
      <div
        onClick={ruleHandler}
        className="absolute ml-5 mt-3 mr-auto text-white cursor-pointer md:text-[18px] xs:text-[30px] flex align-middle"
      >
        <InfoIcon /> &nbsp;
        <span className="xs:hidden md:block">Game Instructions</span>
      </div>
      <div className="p-3">
        <img
          src={logo}
          alt="Logo"
          className="mx-auto xs:w-[60vw] sm:w-[40vw] md:w-[18vw] lg:w-[18vw]"
        />
        <div className="flex justify-center align-middle xs:mt-[12vw] md:mt-[4vw]">
          <span className="xs:text-[20px] md:text-[25px] lg:text-[25px] text-white font-bold">{`Room Code: ${code}`}</span>
          <button
            className={`icon-btn border-none refresh mx-3 h-[25px]`}
            onClick={newRoom}
          >
            <img src={Refresh} style={{ width: "25px", height: "25px" }} />
          </button>
        </div>
        {!waiting ? (
          <div className="m-auto mt-5 text-center">
            <Link to={`/lobby/${code}`}>
              <Button
                display={"bg-btn-bg-primary text-black"}
                text={"Next"}
                clickHandler={() => {
                  newRoom();
                }}
              />
            </Link>
          </div>
        ) : null}

        {settings ? (
          <Modal>
            <Settings
              showSettings={() => showSettings(false)}
              gameCode={code}
              timer={timer}
              setTimer={setTimer}
            />
          </Modal>
        ) : null}

        {rules ? <Rules showRules={() => showRules(false)} /> : null}
      </div>
    </div>
  );
};

export default GenerateLink;