import React, { useEffect } from "react";
import "./HomeNavbar.css";
import PlayerScreen from "../../screens/Player/PlayerScreen/PlayerScreen";
import Tab from "react-bootstrap/Tab";
import HostScreen from "../../screens/Host/HostScreen/HostScreen";
import NavComponent from "../NavComponent";
import { useLocation } from "react-router-dom";
import { auth } from "../../firebase";
const HomeNavbar = () => {
  useEffect(()=>{
    if(location.pathname == "/host" && auth.currentUser.isAnonymous){
      auth.signOut()
    }
  },[auth])
  const location = useLocation();
  return location.pathname == "/host" && !auth.currentUser.isAnonymous ? <HostScreen /> : <PlayerScreen />;
};

export default HomeNavbar;
