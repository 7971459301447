import React from "react";
import rules_1 from "../images/rules-1.png";
import rules_2 from "../images/rules-2.png";
import rules_3 from "../images/rules-3.png";
import rules_4 from "../images/rules_guide.png";
import rules_5 from "../images/rules-5.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MyCarousel = ({ showRules }) => {
  const images = [rules_1, rules_2, rules_3, rules_4, rules_5];

  const dialogStyles = {
    backgroundColor: "transparent",
    width: "100%",
    height: "100%",
  };

  const closeButtonStyles = {
    position: "absolute",
    right: "8px",
    top: "8px",
    color: "#999",
  };

  const isMobile = window.matchMedia("(max-width: 600px)").matches;
  const carouselClass = isMobile ? "horizonatal-carousel" : "";

  return (
    <Dialog
      maxWidth="lg"
      open={true} // Assuming 'showRules' is always set to true when displaying the rules dialog
      fullWidth
      PaperProps={{
        style: {
          ...dialogStyles,
          height: "90%",
          width: "90%",
        },
      }}
      onClose={showRules}
    >
      <DialogContent
        sx={{
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Carousel
          showArrows={true}
          showThumbs={false}
          autoPlay={false}
          infiniteLoop={true}
          useKeyboardArrows={true}
          emulateTouch={true}
          showStatus={false}
          swipeable={true}
          dynamicHeight={true}
          width="100%"
          style={{ height: "100%" }}
          className={carouselClass}
        >
          {images.map((image, index) => (
            <div
              key={index}
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className={`w-full h-full flex items-center justify-center`}
              >
                <img
                  src={image}
                  alt={`Slide ${index + 1}`}
                  className={`max-h-full max-w-full`}
                />
              </div>
            </div>
          ))}
        </Carousel>
      </DialogContent>
      <IconButton
        style={closeButtonStyles}
        onClick={showRules}
        size="small"
      >
        <CloseIcon />
      </IconButton>
    </Dialog>
  );
};

export default MyCarousel;
