import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";

import FlashCard from "../../../components/Flashcard/Flashcard";
import Icons from "../../../components/Icons/Icons";
import DeckIcons from "../../../components/DeckIcons/DeckIcons";
import Button from "../../../components/Button/Button";
import ShowOptions from "../ShowOptions/ShowOptions";

import Fish1 from "../../../images/Fish1-new.png";
import Fish2 from "../../../images/Fish2-new.png";
// import "./RevealScores.css";

import { ref, update, get, child } from "firebase/database";
import { database as db } from "../../../firebase";
import { CodeContext } from "../../../context/context";
import useFirebaseRef from "../../../utils/useFirebaseRef";
import logo from "../../../images/day-icon.png";
import mainLogo from "../../../images/logo_1.png";
import moneyBag from "../../../images/money-bag.png";
import eye from "../../../images/eye-new.png";
import eyeOff from "../../../images/eye-new-off.png";
import "../../../components/Global.css";
import InfoIcon from "@mui/icons-material/Info";
import dayIcon from "../../../images/day-icon.png";
import multiplier3x from "../../../images/3x_multiplier.png";
import multiplier5x from "../../../images/5x_multiplier.png";
import multiplier10x from "../../../images/10x_multiplier.png";
import Rules from "../../Rules/Rules";
import { Dialog, DialogContent } from "@mui/material";
import { makeStyles } from "@mui/styles";

const RevealScores = () => {
  const { roomId, id } = useParams();
  let multiplier = useRef(0);
  // const { code } = useContext(CodeContext);
  const [players, loading] = useFirebaseRef(
    `sessionData/${roomId}/state/${id}`
  );

  const ruleHandler = () => {
    showRules(!rules);
  };

  const [rules, showRules] = useState(false);
  const [score, showScore] = useState(false);
  const clickHandler = (uId) => {
    //	console.log(uId);
    const dbRef = ref(db);
    get(child(dbRef, `sessionData/${roomId}/state/${id}/${uId}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          //console.log(snapshot.val());
          const res = snapshot.val();
          const updates = {};
          updates[`sessionData/${roomId}/state/${id}/${uId}`] = {
            ...res,
            eye: !res.eye,
          };
          update(ref(db), updates);
        } else {
          console.log("No data available");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const classes = makeStyles((theme) => ({
    transparentDialog: {
      backgroundColor: "transparent",
      width: "100%",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));

  if (Number(id) === 5) {
    multiplier.current = (
      <img
        src={multiplier3x}
        className="md:max-w-[70px] xs:max-w-[40px]"
        alt="3x"
      />
    );
  } else if (Number(id) === 8) {
    multiplier.current = (
      <img
        src={multiplier5x}
        className="md:max-w-[70px] xs:max-w-[40px]"
        alt="5x"
      />
    );
  } else if (Number(id) === 10) {
    multiplier.current = (
      <img
        src={multiplier10x}
        className="md:max-w-[65px] xs:max-w-[30px]"
        alt="10x"
      />
    );
  }

  return (
    <div className="overflow-y-auto">
      <div className="float-left ml-3 mt-2">
        <div className="flex flex-col items-start">
          <img
            src={mainLogo}
            alt="Logo"
            className="xs:max-w-[24vw] sm:max-w-[24vw] md:max-w-[10vw] lg:max-w-[10vw] max-h-[20vh]"
          />
          <div
            onClick={ruleHandler}
            className="text-white mt-2 cursor-pointer md:text-[18px] xs:text-[30px] flex align-middle items-center"
          >
            <InfoIcon /> &nbsp;
            <span className="xs:text-[14px] md:text-[18px] xs:max-w-[12%] md:block">
              Game Instructions
            </span>
          </div>
        </div>
      </div>
      <div className="float-right mr-3 mt-2">
        <div className="flex flex-col items-end text-white xs:text-[14px] md:text-[18px]">
          <img
            className="xs:max-w-[12vw] sm:max-w-[12vw] md:max-w-[5vw] lg:max-w-[5vw] cursor-pointer"
            src={moneyBag}
            onClick={() => {
              showScore(true);
            }}
          />
          <b>Score Card</b>
        </div>
      </div>
      <div className="p-1 w-full absolute top-3 mb-auto flex flex-col md:h-[90%] xs:h-screen game">
        <div>
          <div className={`relative flex justify-center items-center top-3`}>
            <div className="absolute mt-0 flex justify-center items-center">
              <span className="absolute pt-3 text-[#fdc440] font-semibold">
                {id}
              </span>
              <img src={dayIcon} className="xs:max-h-[50px] md:max-h-[80px]" />
            </div>
            <p className="relative rounded-full text-center mt-0 px-2 py-2 md:ml-[20vw] xs:ml-[30vw]">
              {Number(id) === 5 || Number(id) === 8 || Number(id) === 10 ? (
                <>{multiplier.current}</>
              ) : null}
            </p>
          </div>
        </div>
        <div className="flex my-auto xs:flex-wrap md:flex-nowrap justify-center items-center">
          {!loading &&
            players &&
            Object.values(players).map((player, index) => {
              //console.log(`${player.name} ${Object.keys(players)[index]}`);
              if (player.eye) {
                return (
                  <div key={index} className="inner-div flex flex-col md:p-1">
                    <div className="flex justify-center items-center">
                      {/* <FlashCard text={player.name} /> */}
                      <div className="team-title min-w-[80%]">
                        {player.name}
                      </div>
                    </div>
                    <div className="mt-3 relative">
                      <div className="flex justify-center">
                        {Number(player.isSubmit.choice) === 2 ? (
                          <ShowOptions fishes={Fish2} />
                        ) : (
                          <ShowOptions fishes={Fish1} />
                        )}
                      </div>
                      <div className="w-full float-right flex justify-end mr-3">
                        <img
                          src={eye}
                          onClick={() =>
                            clickHandler(Object.keys(players)[index])
                          }
                          className="cursor-pointer max-w-[40px] absolute float-right z-10 m-2 top-0"
                        />
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={index} className="inner-div flex flex-col md:p-1">
                    <div className="flex justify-center items-center">
                      {/* <FlashCard text={player.name} /> */}
                      <div className="team-title min-w-[80%]">
                        {player.name}
                      </div>
                    </div>
                    <div className="mt-3 relative">
                      <div className="flex justify-center">
                        <ShowOptions />
                      </div>
                      <div className="w-full float-right flex justify-end mr-3">
                        <img
                          src={eyeOff}
                          onClick={() =>
                            clickHandler(Object.keys(players)[index])
                          }
                          className="cursor-pointer max-w-[40px] absolute float-right z-10 m-2 top-0"
                        />
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
        <center>
          <Link to={`/game/${roomId}/host/scores`}>
            <button
              onClick={(e) => {
                get(child(ref(db), `sessionData/${roomId}/hostProperties`))
                  .then((snapshot) => {
                    if (snapshot.exists()) {
                      const res = snapshot.val();
                      //console.log(res);
                      const updates = {};
                      updates[`sessionData/${roomId}/hostProperties`] = {
                        ...res,
                        showScore: true,
                      };
                      if (!res.showScore) {
                        update(ref(db), updates);
                      }
                    } else {
                      console.log("No data available");
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }}
              className={`bg-btn-bg-primary btn-lg mt-0 min-w-[90px] text-[20px] font-medium py-2.5 rounded-[15px] px-5 text-white inline-block bg-center bg-gradient-to-r from-[#ff914d] to-[#e8a04d]`}
            >
              {"Scores"}
            </button>
          </Link>
        </center>
      </div>

      {rules ? <Rules showRules={() => showRules(false)} /> : null}
    </div>
  );
};

export default RevealScores;
