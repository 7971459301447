import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Endgame = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate("/");
    }, 5000);
  }, []);
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <img
        src={`https://ik.imagekit.io/sjbtmukew5p/Fishy_Equilibrium/thankyou_.png`}
        alt="game end"
        className="h-1/5 w-3/6"
      />
    </div>
  );
};

export default Endgame;
